import { Component, ErrorInfo, ReactNode } from 'react'

import { datadogRum } from '@datadog/browser-rum'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}
export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    datadogRum.addError(error, errorInfo, 'source')
  }

  public render() {
    return this.props.children
  }
}
