import React from 'react'
import 'isomorphic-fetch'
import { ApolloClient } from 'apollo-client'
import { fromPromise, ApolloLink } from 'apollo-link'
import { ApolloProvider } from '@apollo/react-hooks'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { NextPage } from 'next'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

import { fetchToken } from './'
import { removeUrlParameter, handleAuthRedirect } from '../handleAuthRedirect/'
import { REFRESH_TOKEN_QUERY_PARAM } from '../../constants'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null
let router

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 */
export function withApollo<PageProps>(PageComponent: NextPage<PageProps>) {
  type ApolloPageProps = PageProps & { router: unknown } & {
    apolloClient?: ApolloClient<NormalizedCacheObject> | null
    apolloState?: NormalizedCacheObject
  }
  const WithApollo: NextPage<ApolloPageProps> = ({
    apolloClient,
    apolloState,
    ...pageProps
  }) => {
    const client = apolloClient ?? initApolloClient(apolloState)
    router = pageProps.router
    return (
      <ApolloProvider client={client}>
        <PageComponent {...(pageProps as PageProps)} />
      </ApolloProvider>
    )
  }

  return WithApollo
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
function initApolloClient(initialState?: NormalizedCacheObject) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState)
  }

  return apolloClient
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.extensions?.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(
            fetchToken()
              .then(() => {
                const url = removeUrlParameter(
                  window.location.href,
                  REFRESH_TOKEN_QUERY_PARAM
                )
                const path = handleAuthRedirect(url)
                return router.push(path)
              })
              .catch((error) => {
                console.log(error)
              })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation?.getContext()?.headers
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${(accessToken as string) ?? ''}`,
                },
              })
              return forward(operation)
            })
      }
    }
  }
})

const authLink = setContext((_, { headers, ...context }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      UserType: 'admin',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...context,
  }
})

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
})

const link = ApolloLink.from([errorLink, authLink, httpLink])

/**
 * Creates and configures the ApolloClient
 */
function createApolloClient(
  initialState: NormalizedCacheObject = {}
): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link,
    cache: new InMemoryCache().restore(initialState),
  })
}
