import { INITIAL_FILTER_VALUE, INITIAL_PAGE_VALUE } from '../../constants'

export function removeUrlParameter(url: string, paramToDelete: string) {
  const newUrl = new URL(url)
  if (newUrl.search.includes(paramToDelete)) {
    newUrl.searchParams.delete(paramToDelete)
    return newUrl
  }
  return newUrl
}

export function handleAuthRedirect(url: URL) {
  if (url.pathname.includes('bookings') && !url.searchParams?.has('page')) {
    url.searchParams.append('page', `${INITIAL_PAGE_VALUE}`)
    url.searchParams.append('filter', `${INITIAL_FILTER_VALUE as string}`)
    return `${url.pathname}${url.search}`
  } else {
    return url.href
  }
}
