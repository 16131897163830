import fetch from 'isomorphic-unfetch'

import { REFRESH_TOKEN_QUERY_PARAM } from '../../constants'

const JWT_LOGIN_URL = process.env.NEXT_PUBLIC_JWT_LOGIN_URL ?? ''
const JWT_TOKEN_URL = process.env.NEXT_PUBLIC_JWT_TOKEN_URL ?? ''
const CALLBACK_URL = process.env.NEXT_PUBLIC_CALLBACK_URL ?? ''

export const fetchToken = async () => {
  if (typeof window === 'undefined') {
    return
  }
  const url: URL = new URL(window.location.href)
  const refreshToken = url.searchParams?.has(REFRESH_TOKEN_QUERY_PARAM)
    ? url.searchParams.get(REFRESH_TOKEN_QUERY_PARAM)
    : ''
  let callBackUrl = ''
  if (refreshToken === '') {
    callBackUrl = window.location.href
    window.location.href = `${JWT_LOGIN_URL}${callBackUrl ?? CALLBACK_URL}`
    return null
  } else {
    const res = await fetch(
      `${JWT_TOKEN_URL}?${REFRESH_TOKEN_QUERY_PARAM}=${refreshToken as string}`,
      {
        method: 'POST',
      }
    )

    if (res.status < 200 || res.status >= 300) {
      callBackUrl = window.location.href
      window.location.href = `${JWT_LOGIN_URL}${callBackUrl ?? CALLBACK_URL}`
      return
    }

    const token = await res.text()
    localStorage.setItem('token', token)
    return token
  }
}
