import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import { withRouter } from 'next/router'
import { datadogRum } from '@datadog/browser-rum'

import 'design-system/theme/norbert/index.css'
import appStyle from './app.module.css'

import Container from 'design-system/src/components/Container/Container'
import { withApollo } from 'src/lib/auth/withApollo'
import { ErrorBoundary } from 'src/components/ErrorBoundary'

const AdminSearch = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
      service: 'admin-search',
      env: process.env.NODE_ENV,
      sampleRate: 100,
      trackInteractions: true,
    })
  }, [])

  return (
    <main>
      <ErrorBoundary>
        <Container className={appStyle.app}>
          <Component {...pageProps} />
        </Container>
      </ErrorBoundary>
    </main>
  )
}

export default withRouter(withApollo(AdminSearch))
